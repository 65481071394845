<template>
  <div>
    <b-row class="d-flex align-items-center mb-2">
      <b-col>
        <b-form-input
          id="pcode"
          v-model="productFilter.keyword"
          placeholder="Mamül Kodu"
          @keydown.enter="searchData"
        />
      </b-col>
      <b-col>
        <v-select
          id="id_product_groups"
          v-model="productFilter.id_product_groups"
          class="bg-white rounded"
          label="title"
          placeholder="Ürün Grubu"
          :reduce="item => item.id"
          :options="productGroups"
        />
      </b-col>
      <b-col>
        <v-select
          id="id_product_qualities"
          v-model="productFilter.id_product_qualities"
          label="title"
          class="bg-white rounded"
          placeholder="Kalite"
          :reduce="item => item.id"
          :options="productQualities"
        />
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="searchData"
        >
          <FeatherIcon icon="SearchIcon" /> Ara
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProductSearch',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    productFilter() {
      return this.$store.getters['catalogs/productFilter']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
    productQualities() {
      return this.$store.getters['productQualities/dataList']
    },
  },
}
</script>
